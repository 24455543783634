import { useLayoutEffect } from 'react';
import { Link as MuiLink, Typography } from '@mui/material';
import { LoadingPage } from '@noah-labs/fe-shared-ui-components';
import UAParser from 'ua-parser-js';

const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.noah.app.twa';
const feWebUrl = 'https://app.noah.com';
//  TODO: replace with AppStore link once available
const appStoreUrl = 'https://app.noah.com';

function detectDeviceAndNavigate(): void {
  const parser = new UAParser();
  const { os } = parser.getResult();

  const isIOS = os.name === 'iOS';
  const isAndroid = os.name === 'Android';

  if (isAndroid) {
    window.location.assign(googlePlayUrl);
    return;
  }
  if (isIOS) {
    window.location.assign(appStoreUrl);
    return;
  }
  window.location.assign(feWebUrl);
}

export default function GetApp(): React.ReactElement {
  useLayoutEffect(() => {
    setTimeout(() => {
      detectDeviceAndNavigate();
    }, 1000);
  });

  return (
    <LoadingPage message="Redirecting">
      <Typography>
        Something wrong? <MuiLink href="https://noah.com">Go Back.</MuiLink>
      </Typography>
    </LoadingPage>
  );
}
